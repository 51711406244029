.App-header .navbar {
  z-index: 10;
  padding: 0 20px;
  background-color:#161618;
  border-bottom: 1px solid #444;
}
*{
  font-size:12px;
  -webkit-user-select: none; 
      -webkit-touch-callout: none; 
      -moz-user-select: none; 
      -ms-user-select: none; 
      user-select: none;   
}
*:focus{
  outline: none !important;
}
::selection,::-moz-selection,select option:hover {
  background: #28558e; /* WebKit/Blink Browsers */
}


.App-header .navbar a,.navbar-text{
  color:white;
  cursor:pointer;
}
.loading-demo{
  text-align: center;
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.loading-demo .fas.fa-cog {
  font-size: 44px;
  margin-bottom: 20px;
   -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.loading-demo a {
  color: #007bff
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.ReactModal__Overlay {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.8) !important;
}

.logo-container {
    /*width: 140px; */
    /* height: 56px; */
    /* margin-left: -70px; */
    display: flex;
    /* margin-top: -4px; */
    align-items: center;
    justify-content: center;
    font-size: 15px;
    /*background: #fffcfc;*/
    margin-left: -30px;
}
.navbar-brand {
  padding: 0px;
  margin: 0px 10px;
}
.navbar-brand {
  color: rgba(0, 0, 0, 0.9) !important;
}

.navbar-text *{
  padding: 0 2px;
  cursor:pointer;
  color:#999 !important;
}

.mm-dropzone{
  background-color:#28558e33;
  border:2px dashed #28558e;
  border-radius: 10px;
  padding:15px;
  text-align: center;

}
.mm-image{
  object-fit: cover;
}
.mm-slider-img{
  height:100px;
}
.thumb .mm-slider-img{
  height:45px;
  object-fit:contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo{
    background: url("../assets/logo-transparent.png") no-repeat;
    background-size: 100%;
    width: 89px;
    height: 60px;
    background-position: center;
}

.form-control {
  margin-top:10px;
  padding:0;
  border: none;

}
.ReactModal__Content {
  max-width:400px;
  width: 100%;
  top:unset !important;
  left:unset !important;
  bottom:unset !important;
  right:unset !important;
  color: whitesmoke !important;
  background: rgb(18, 17, 19) !important;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid #999;
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  /*border-top: 5px solid rgba(0, 0, 0, 0.5);*/
  border-bottom: 5px solid rgba(0, 0, 0, 1);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid #999;
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 1);
  /*border-right: 5px solid rgba(0, 0, 0, 0.5);*/
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.Pane.vertical.Pane1 {
  overflow: auto;
}
.Pane.horizontal.Pane2 {
  overflow: auto;
}
.layers-panel{
  margin:20px 0 !important;
}
.Pane.horizontal.Pane2, .Pane.vertical.Pane2 .Pane.vertical.Pane2{
  padding: 15px;
  color:whitesmoke;
}
.Pane{
  background-color:#161618 !important;
}

#three-map{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor:grab;
  /*background:url("../assets/mergin.jpg") no-repeat;*/
  background-size:100% 100%;
}
#three-map:active{
  cursor: grabbing;
}
#axes-helper{
  width:60px;
  height: 60px;
  max-width:100px;
  max-height: 100px;
  position: absolute;
  bottom: 20px;
  left: 0px;
  /*border: 1px solid black;*/
}

#info-helper{
  position: absolute;
  cursor: pointer;
  color:whitesmoke;
  width:100%;
  height: 20px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color:#161618;
  border: 1px solid #222;
  padding: 0px 10px;
}

#coords {
  text-align: right;
  position: relative;
  width: 50%;
  display: inline-block;
  font-size: 10px;
}

#epsg {
  text-align: left;
  display: inline-block;
  width: 50%;
  position: relative;
  font-size: 10px;
}