.model-item {
	padding: 20px;
	margin-bottom: 20px;
	/*cursor: pointer;*/
	position: relative;
	/*border:1px solid #28271d;*/
	background:#0d0d0e;
}
.model-item .settings{
	cursor: pointer;
	position: absolute;
	top:5px;
	right: 5px;
	width:16px;
	height:16px;
}
.model-item:hover{
	/*background:#28558e;*/
}
.model-item-inner {
	background:#28558e33;
	margin: 15px;
	padding:10px;
}
.mm-form-block{
	margin-bottom: 15px;
}
.mm-btn{
	opacity: 1!important;
	background-color: black;
	border-radius: 10px;
	border: none;
	cursor: pointer;
	width:100%;
	color: whitesmoke;
    height: 40px;
}

.mm-btn:hover{
	background-color:#333 !important;
}
.model-item-inner > button{
	margin-top:30px;
}

.ModelList {
	min-height: 100%
}

.ModelList h3{
	margin-bottom: 40px
}

.ModelList.container{
	padding:20px;
	/*margin-bottom: 80px;*/
}
.model-item input {
	background: transparent;
    border: none;
    border-bottom: 1px solid whitesmoke;
    color: whitesmoke;
	width: 100%;
}
.model-item input:focus {
	outline: none;
}
.model-item label {
	margin-bottom: 10px;
	width: 100%;
	display: block;
}
select {
	width: 100%;
    height: 30px;
}