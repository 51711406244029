* {
  box-sizing: border-box;
}
html,body {
	width:100%;
	height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
	height: 100%;
	width: 100%;
	position: absolute;
  background-color: #39372a;
}
.App > header {
  height:56px;
}
.App > main {
  height:calc(100% - 56px);
  position: relative;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
